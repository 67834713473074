@charset "utf-8"

@import "../../styles/lib/variables.sass"
@import "../../styles/lib/mixins.sass"


















































































































































































































































































































































































































































































































































































































































































.request-group-page
  .msg
    margin-bottom: m(2)
    padding: m(2) m(3)
    p
      color: $white
    &.error
      background-color: $warning-color
    &.success
      background-color: $success-color
  .label-control
    &+.label-control
      &:before
        margin-left: m(1)
        margin-right: m(1)
        content: "/"
  .action
    padding-top: m(4)
    text-align: center
  p
    &.has-error
      color: $red
  .calculation
    .total,
    .info
      text-align: right
    .discount
      color: $warning-color
    &.has-border
      margin-top: m(1)
      .col
        padding-top: m(2)
        border-top: 1px solid $border-color

+xs
  .request-group-page
    .col
      &.label
        padding-bottom: 0
      &.control
        padding-top: 0
    .calculation
      .info
        text-align: left
